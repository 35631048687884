import React, { useContext } from "react"
import LayerContext from "./LayerContext"

export default ({ children, ...props }) => {
  const cS = Array.isArray(children)? children : [children]
  const { layerState, setLayer } = useContext(LayerContext)

  return (
    <div className={"contentSection"}>
      {cS.map(child => {
        const active = child.props["data-layer"] === layerState.selectedLayer
        return (
          <div
            key={child.props["data-layer"]}
            style={{ display: active? "block" : "none" }}
          >
            {child}
          </div>
        )
      })}

      <div className={"project-control-wrapper"}>
        <button className={"project-button prev"}
                onClick={() => setLayer(layerState.selectedLayer - 1)}
                hidden={layerState.selectedLayer <= 1}
        >
          prev
        </button>
        <button className={"project-button next"}
                onClick={() => setLayer(layerState.selectedLayer + 1)}
                hidden={layerState.selectedLayer >= 8}
        >
          next
        </button>
      </div>
    </div>
  )
}
